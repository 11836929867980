<template>
  <div>
    <sav-filters
      :state-options="stateOptions"
      :state-value.sync="stateValue"
      :person-options="personOptions"
      :person-value.sync="personValue"
      :device-options="deviceOptions"
      :device-value.sync="deviceValue"
    />
    <b-card
      v-if="$store.state.auth.role === 1"
      no-body
    >
      <b-card-header>
        <h5>SAV</h5>
        <modal-new-event :device-id="null" />
      </b-card-header>
      <b-table
        v-if="events && events.length > 0"
        class="position-relative"
        :items="events"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Aucun SAV trouvé"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(id)="data">
          <div>
            <small>
              <span
                v-if="data.item.title === 'SAV'"
              >SI</span>
              <span
                v-else
              >EVE</span>{{ data.item.id }}
            </small>
          </div>
        </template>

        <template #cell(createdAt)="data">
          <modal-view-event
            :event="data.item"
            :files="data.item.files"
          />
        </template>

        <template #cell(deviceId)="data">
          <small>
            {{ data.item.device.name }}
          </small>
          <b-avatar
            v-if="lugniumList.includes(data.item.deviceId)"
            v-b-tooltip.hover
            title="Lugnium"
            variant="light-info"
            size="24"
          >
            <feather-icon
              icon="ShieldIcon"
              size="14"
            />
          </b-avatar>
          <b-avatar
            v-if="data.item.boolData"
            v-b-tooltip.hover
            title="Gmax > 300G"
            variant="light-danger"
            size="24"
          >
            <feather-icon
              icon="AlertTriangleIcon"
              size="14"
            />
          </b-avatar>
        </template>

        <template #cell(startDate)="data">
          <div>
            <small>
              {{ data.item.startDate | displayDate }}
            </small>
          </div>
        </template>

        <template #cell(endDate)="data">
          <small>
            {{ data.item.endDate | displayDate }}
          </small>
        </template>

        <template #cell(state)="data">
          <b-badge
            pill
            :variant="`light-${resolveStateVariant(data.item.state)}`"
            class="text-capitalize d-flex justify-content-center"
          >
            {{ data.item.state }}
          </b-badge>
        </template>

        <template #cell(comment)="data">
          <div>
            <small>
              {{ data.item.comment | truncate(20) }}
            </small>
          </div>
        </template>

        <template #cell(personInCharge)="data">
          <div>
            <small>
              {{ data.item.personInCharge }}
            </small>
          </div>
        </template>

        <template #cell(action)="data">
          <div class="d-flex justify-content-end">
            <modal-edit-event
              :event="data.item"
              :files="data.item.files"
            />
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              v-b-tooltip.hover
              title="Supprimer"
              variant="flat-danger"
              class="btn-icon rounded-circle"
              @click="deleteEvent(data.item)"
            >
              <feather-icon
                icon="DeleteIcon"
                size="20"
              />
            </b-button>
          </div>
        </template>
      </b-table>
      <div
        v-else
        class="text-center"
      >
        <b-spinner
          v-if="isLoading"
          class="mt-5 mb-5"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BButton, BTable, BBadge,
  BCardHeader, VBTooltip, BSpinner, BAvatar,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import ModalNewEvent from '../events/ModalNewEvent.vue'
import ModalEditEvent from '../events/ModalEditEvent.vue'
import ModalViewEvent from '../events/ModalViewEvent.vue'
import SavFilters from './SavFilters.vue'

export default {
  components: {
    BCard,
    BButton,
    ModalNewEvent,
    ModalEditEvent,
    ModalViewEvent,
    BTable,
    BBadge,
    BCardHeader,
    SavFilters,
    BSpinner,
    BAvatar,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isLoading: false,
      events: null,
      today: new Date(),
      tableColumns: [
        { key: 'id', label: 'N°', sortable: true },
        { key: 'createdAt', label: 'Client', sortable: false },
        { key: 'deviceId', label: 'Device', sortable: false },
        { key: 'startDate', label: 'Début', sortable: true },
        { key: 'endDate', label: 'Fin', sortable: true },
        { key: 'state', label: 'État', sortable: true },
        { key: 'comment', label: 'Commentaire', sortable: false },
        { key: 'personInCharge', label: 'Personne', sortable: true },
        { key: 'action', label: 'Actions', sortable: false },
      ],
      sortBy: 'id',
      isSortDirDesc: true,
      stateOptions: ['A faire', 'En cours', 'Terminé', 'Retard'],
      // pagination
      perPage: 10,
      currentPage: 1,
      // filters
      stateValue: null,
      personValue: null,
      personOptions: [],
      deviceValue: null,
      deviceOptions: [],
      // lugnium info
      lugniumList: [],
    }
  },
  watch: {
    stateValue() {
      this.filterSav()
    },
    personValue() {
      this.filterSav()
    },
    deviceValue() {
      this.filterSav()
    },
  },
  mounted() {
    this.getEvents()
  },
  methods: {
    async getEvents() {
      this.isLoading = true
      if (this.$store.state.event.eventsList.length <= 0) {
        store.dispatch('event/findAllEvents')
          .then(response => {
            const lugnium = response.filter(event => event.title === 'Lugnium' && (Date.parse(event.endDate) >= Date.parse(this.today.toString())))
            lugnium.forEach(lug => {
              this.lugniumList.push(lug.deviceId)
            })
            this.events = response.filter(event => event.title === 'SAV')
            if (this.events && this.events.length > 0) {
              this.events.forEach(event => {
                this.$store.dispatch('file/fetchFile', { eventId: event.id })
                  .then(res => {
                    if (res && res.length > 0) {
                      // eslint-disable-next-line no-param-reassign
                      event.files = []
                      res.forEach(file => {
                        const projectImage = Buffer.from(file.content).toString('base64')
                        // eslint-disable-next-line no-param-reassign
                        file.content = projectImage
                        // eslint-disable-next-line no-param-reassign
                        event.files.push(file)
                      })
                    }
                  })
              })
              this.eventsTotal = this.events
              const personArray = []
              const deviceArray = []
              response.filter(event => event.title === 'SAV').forEach(elem => {
                if (elem.personInCharge) {
                  personArray.push(elem.personInCharge)
                }
                if (elem.device) {
                  deviceArray.push(elem.device.name)
                }
              })
              this.personOptions = [...new Set(personArray)]
              this.deviceOptions = [...new Set(deviceArray)]
            }
            this.isLoading = false
          })
          .catch(err => {
            console.log('err', err)
            this.events = null
            this.isLoading = false
          })
      } else {
        const lugnium = this.$store.state.event.eventsList.filter(event => event.title === 'Lugnium' && (Date.parse(event.endDate) >= Date.parse(this.today.toString())))
        lugnium.forEach(lug => {
          this.lugniumList.push(lug.deviceId)
        })
        this.events = this.$store.state.event.eventsList.filter(event => event.title === 'SAV')
        if (this.events && this.events.length > 0) {
          this.events.forEach(event => {
            this.$store.dispatch('file/fetchFile', { eventId: event.id })
              .then(res => {
                if (res && res.length > 0) {
                  // eslint-disable-next-line no-param-reassign
                  event.files = []
                  res.forEach(file => {
                    const projectImage = Buffer.from(file.content).toString('base64')
                    // eslint-disable-next-line no-param-reassign
                    file.content = projectImage
                    // eslint-disable-next-line no-param-reassign
                    event.files.push(file)
                  })
                }
              })
          })
          this.eventsTotal = JSON.parse(JSON.stringify(this.events))
          const personArray = []
          const deviceArray = []
          this.$store.state.event.eventsList.filter(event => event.title === 'SAV').forEach(elem => {
            if (elem.personInCharge) {
              personArray.push(elem.personInCharge)
            }
            if (elem.device) {
              deviceArray.push(elem.device.name)
            }
          })
          this.personOptions = [...new Set(personArray)]
          this.deviceOptions = [...new Set(deviceArray)]
        }
        this.isLoading = false
      }
    },
    deleteEvent(event) {
      this.$swal({
        title: 'Êtes-vous sûr de vouloir supprimer ?',
        text: 'Cette action est irréversible!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui, supprimer!',
        cancelButtonText: 'Annuler',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('event/deleteEvent', event.id)
            .then(() => {
              if (event.files && event.files.length > 0) {
                event.files.forEach(file => {
                  store.dispatch('file/deleteFile', file.id)
                })
              }
              this.$swal({
                icon: 'success',
                title: 'Supprimé!',
                text: 'L\'évènement a bien été supprimé',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
          this.getEvents()
          this.$router.go()
        }
      })
    },
    resolveStateVariant(data) {
      if (data === 'Terminé') return 'success'
      if (data === 'En cours') return 'info'
      if (data === 'Retard') return 'danger'
      if (data === 'A faire') return 'warning'
      return 'dark'
    },
    filterSav() {
      this.events = this.eventsTotal
      if (this.stateValue) {
        this.events = this.events.filter(sav => sav.state === this.stateValue)
      }
      if (this.personValue) {
        this.events = this.events.filter(sav => sav.personInCharge === this.personValue)
      }
      if (this.deviceValue) {
        this.events = this.events.filter(sav => sav.device.name === this.deviceValue)
      }
    },
  },
}
</script>
